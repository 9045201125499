import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Add on Modules</span> of our </span>
              SuperRare Clone Script Software</h2>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Build your community
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Live Customer Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Blogs
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Multi-language Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Sub - Admin access
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Build your community
                      </h3>
                      <p className="pharagraph">Our script lets the admin build a community for the user as well as for the  developers to be engaged with the platform updates and gather suggestions to be  implemented for improving the platform.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent />
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="360px" height="249px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/build-your-community.png" alt="Build your community" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Live Customer Support
                      </h3>
                      <p className="pharagraph">Our clone script comes with live customer support to assist your customers instantly resolve the issue that occurs in the platform.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="361px" height="291px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/live-customer-support.png" alt="Live Customer Support" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Blogs
                      </h3>
                      <p className="pharagraph">Our super rare clone script provides you with blogs to attract new customer and keeps your audience engaged while building a brand with solid reputation
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="364px" height="226px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/blogs.png" alt="Blogs" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Multi-language Support</h3>
                      <p className="pharagraph">Our Super rare clone script offers multi language support to reduce the language barrier and reach the customer around the globe.while offering a better experience to the global customers.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="286px" height="275px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/multi-language-support.png" alt="Multi-language Support" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Sub - Admin access
                      </h3>
                      <p className="pharagraph">Our Super Rare clone script can be integrated with sub admin panel to provide access for a specific task to multiple support staffs and sub admin for managing the approval of the articles and tracking the transactions, solving the disputes in the platform
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="343px" height="304px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/sub-admin-access.png" alt="Sub - Admin access" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon