import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Superrare/Banner"
import Whatis from "../components/Superrare/Whatis"
//import QuickviewSection from "../components/Superrare/Quickview"
import HowOur from "../components/Superrare/HowOur"
import TopFeatures from "../components/Superrare/TopFeatures"
import CoreFeatures from "../components/Superrare/CoreFeatures"
import SecurityofOur from "../components/Superrare/SecurityofOur"
import TechStack from "../components/Superrare/TechStack"
import FaqSection from "../components/Superrare/FaqSection"
import WhyShould from "../components/Superrare/WhyShould"
import DevApproach from "../components/Superrare/DevApproach"
import WhyChoose from "../components/Superrare/WhyChoose"
import Addon from "../components/Superrare/Addon"
import OurAndroid from "../components/Superrare/OurAndroid"
import Testimonial from "../components/Superrare/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"





const Clone = () => (
  <section className="landing clone">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>SuperRare Clone Script to develop NFT Marketplace like SuperRare</title>
        <meta name="description" content="SuperRare Clone Script is a readymade NFT Marketplace Clone Script built on Ethereum Blockchain that allows you to launch an NFT Marketplace like SuperRare" />
        <meta property="keywords" content="SuperRare Clone Software, White Label SuperRare Clone, Create NFT Marketplace like SuperRare, White Label SuperRare Clone App Development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="SuperRare Clone Script to develop NFT Marketplace like SuperRare" />
        <meta property="og:description" content="SuperRare Clone Script is a readymade NFT Marketplace Clone Script built on Ethereum Blockchain that allows you to launch an NFT Marketplace like SuperRare" />
        <meta property="og:url" content="https://www.coinsclone.com/superrare-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/superrare-clone-script.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="SuperRare Clone Script is a readymade NFT Marketplace Clone Script built on Ethereum Blockchain that allows you to launch an NFT Marketplace like SuperRare" />
        <meta name="twitter:title" content="SuperRare Clone Script to develop NFT Marketplace like SuperRare" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/superrare-clone-script.png" />
        <link rel="canonical" href="https://www.coinsclone.com/superrare-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | SuperRare Clone Script </span>
        </div>
      </div>
      <Whatis />
      {/* <QuickviewSection /> */}
      <HowOur />
      <WhyShould />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <OurAndroid />
      <WhyChoose />
      <DevApproach />
      <Testimonial />
      <TechStack />
      <FaqSection />
      <UserFriendly />
      
    </Layout>
  </section>
)

export default Clone
 