import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {


  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Core Features</span> of </span>
              Our SuperRare Clone Script</h2>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Mint and List your own NFTs
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Narrow down your search
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Manage Listed Collections
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Join as an Artist
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Claim your Royalties
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Curated Gallery
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Bid and Buy
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Voting System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Admin Profit Settings
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Multi-currency Support
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Mint and List your own NFTs
                        </h3>
                        <p className="pharagraph">Our clone script facilitate user to mint their own NFTs on ethereum network by uploading collectible which include images,video,gif,audio,domain and names etc
                        </p>
                        <div className="text-left mt-4">
                          <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="245px" height="332px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/mint-and-list-your-own-nf-ts.png" alt="Mint and List your own NFTs" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Narrow down your search
                        </h3>
                        <p className="pharagraph">Our filter option lets you show collectibles  based on interest and quickly filter between artist, filetype, price range,and date minted.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="442px" height="300px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/narrow-down-your-search.png" alt="Narrow down your search" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Manage Listed Collections

                        </h3>
                        <p className="pharagraph">The admin console facilitates to manage, approve/reject the listings done in the platform.
                          The collectibles can be activated or deactivated any time from the admin panel.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="376px" height="320px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/manage-listed-collections.png" alt="Manage Listed Collections" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Join as an Artist
                        </h3>
                        <p className="pharagraph">The SuperRare clone script provides an option for the users to sign in as an artist to have a specialized access to get a wide range of followers and help in building celebrity profiles.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="272px" height="332px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/join-as-an-artist.png" alt="Join as an Artist" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Claim your Royalties
                        </h3>
                        <p className="pharagraph">The NFTs minted can be claimed with royalties whenever sold to another user anywhere in the network and our smart contracts enables to credit the royalties to the creator automatically.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="411px" height="283px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/claim-your-royalties.png" alt="Claim your Royalties" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Curated Gallery
                        </h3>
                        <p className="pharagraph">Our clone script provides with gallery to showcase various collectible from artist and collectors
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="432px" height="285px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/curated-gallery.png" alt="Curated Gallery" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Bid and Buy
                        </h3>
                        <p className="pharagraph">The SuperRare clone software facilitates Bid and participate in live auctions and can buy their desired NFTs for a convenient price which can be tracked with open source marketplace.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw315" width="261px" height="312px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/bid-and-buy.png" alt="Bid and Buy" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Voting System
                        </h3>
                        <p className="pharagraph">Our superRare script allows user to cast vote for their favorite artist ,collectible and governance
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="306px" height="289px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/voting-system.png" alt="Voting System" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Admin Profit Settings
                        </h3>
                        <p className="pharagraph">Our superRare readymade clone script lets you modify the commission settings for minting, bidding and withdrawal of the NFTs from the admin panel.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="366px" height="232px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/admin-profit-settings.png" alt="Admin Profit Settings" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Multi-currency Support
                        </h3>
                        <p className="pharagraph">Our superRare clone script supports various currencies and lets your customer buy  collectibles around the world even with fiat currencies.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="314px" height="301px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/multi-currency-support.png" alt="Multi-currency Support" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures