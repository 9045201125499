import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {


  render() {

    return (
      <section className="whyshould pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Why should</span> you start a </span>
                NFT Marketplace using a SuperRare clone script?
              </h2>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <img width="552px" height="594px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/why-should-you-start.png" alt="superrare clone script software" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >Quick deployment 
                </li>
                <li className="pharagraph" >Art appreciators can purchase NFTs instantly and securely by connecting crypto wallets.
                </li>
                <li className="pharagraph" >A smart and effective solution to start an NFT Marketplace business.
                </li>
                <li className="pharagraph" >Built on the Ethereum network
                </li>
                <li className="pharagraph" >The SuperRare clone script offers multi-currency support.
                </li>
                <li className="pharagraph" >Supports multiple transactions such as direct purchases as well as auctioning and bidding.</li>
                <li className="pharagraph" >Your users can get live updates on the latest and trending NFTs.
                </li>
                <li className="pharagraph" >A powerful user-friendly admin dashboard that enables you to manage all activity.
                </li>
                <li className="pharagraph" >Your NFT Marketplace will be entirely secure based on smart contracts and it is practically impossible to steal NFTs from the repositories created by our SuperRare clone script.
                </li>
                <li className="pharagraph" >SuperRare clone script helps to make revenue with every transaction, minting NFTs, bidding, and trading NFTs.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould