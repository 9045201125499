import React from 'react'

import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Top Features</span> of </span>
                Our SuperRare Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Collect Digital Art</h3>
                <p className="pharagraph">Our super Rare clone script is built on Ethereum based platform that  allows users to mint,buy,sell ownership rights to the collectors via Non- fungible token.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/collect-digital-art.png" alt="Collect Digital Art" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/follow-top-artists.png" alt="Follow Top Artists" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">Follow Top Artists</h3>
                <p className="pharagraph">Our super rare clone script allows users to follow their favorite artist / owner and receive updates from them on their latest works and they can buy their collection and creation.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Decentralized protocol</h3>
                <p className="pharagraph">Users are able to track the transaction details of the listed NFTs in the marketplace through the Ethereum block chain in which our clone script can be designed in completely decentralized protocol.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/decentralized-protocol.png" alt="Decentralized protocol" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/multi-wallet-support.png" alt="Multi-wallet Support" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">Multi-wallet Support</h3>
                <p className="pharagraph">Our clone script supports all ethereum based wallets such as Metamask, Atomic wallet and scalable to support other chain wallets.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>

          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Unified Interface</h3>
                <p className="pharagraph">Our super rare clone script provides users with rich viewing experience and optimal  viewing across various devices such as mobile,desktop and tablet.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/unified-interface.png" alt="Unified Interface" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures