import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
        <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
                White Label SuperRare Clone Script?</h2>
            </div>
          </div>

            <div className="text-center">
              <p className="pharagraph" >SuperRare clone script is a pre-designed NFT Marketplace clone script that is trending in the global crypto marketplace. It is completely an optimizable clone script that is specially introduced for budding entrepreneurs who wants to enter the NFT world by launching an NFT Marketplace in a cost-effective way. It is a feature-packed white-label NFT Marketplace software that is crafted on top of the Ethereum blockchain network. The SuperRare clone script is completely designed, developed, multi-tested, and ready for launch.
              </p>
              <p className="pharagraph mb-0" >By using the premium SuperRare clone script, you can build an NFT Marketplace on the Ethereum blockchain within 7 days which looks 100% similar to SuperRare. Besides, the workflow of this clone script will be the same as the existing platform - SuperRare. Your users can trade any digital items in the name of NFTs. Digital items can exist in the form of animations, 3D artwork (for example photoshop), GIFs, mash-ups, videos, or documents. Therefore, with our SuperRare clone script, you can create a social network type of NFT Marketplace platform that acts as a hub for creators and NFT enthusiasts.
              </p>
            </div>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis