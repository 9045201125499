import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          <h2 className="heading-h2"><span className="bluecolor">Frequently </span>Asked Questions</h2>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What are the Benefits of using the SuperRare clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">You can use this clone script to build a decentralized NFT Marketplace like SuperRare within a few days. The SuperRare clone script primarily caters to entrepreneurs who want to set up an NFT Marketplace at an affordable cost. It also comes with a social networking feature that allows your users to interact with each other and exchange opinions about various pieces of NFTs.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Is the SuperRare clone script pretty expensive?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">It is not. In fact, the entire purpose of using the SuperRare clone script is that you don’t need to spend as much money as you would spend on the scratch method. You will get all the essential features in the SuperRare clone script but at a much lower rate.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Can I Customize the SuperRare Clone Software?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Yes, of course, you can customize the clone script according to your business needs in a stress-free way.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How soon can I create an NFT Marketplace using the SuperRare clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">There is no fixed time for developing an NFT Marketplace as it varies based on customization and integration of features you want. However, we guarantee that we would deliver the project within the given time frame.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How much does it cost to develop an NFT Marketplace like the SuperRare clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The cost to create a non-fungible token marketplace will be completely based on your business requirements and the customizations you make on this script. You can contact our team of experts and get the exact cost estimate for your project.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>
    </section>
  )
}

export default FaqSection