import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const OurAndroid =()=> {

    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            
            <h2 className="heading-h2"><span className="heading-h3">Our <span className="bluecolor">Android & iOS App </span></span>
              For SuperRare Clone App Development</h2>
            <p className="pharagraph head">Launch a user-friendly and eye-catching cryptocurrency trading mobile app like SuperRare
            </p>
          </div>

          <div className="row table-content orderflex mobilewhitebox">
            <div className="col-md-12 col-lg-7 order2 mb-0">
              <p className="pharagraph" >Create and deploy an eye-catching NFT Marketplace mobile app like SuperRare with our white label SuperRare clone script. We offer quality-rich NFT Marketplace clone app development services with impeccable outcomes. Our premium SuperRare clone script can be used as a powerful back-end platform to build a high-performance NFT Marketplace mobile app similar to the SuperRare app.
              </p>
              <p className="pharagraph" >You can create both an NFT Marketplace website and a mobile application by using our SuperRare clone script. The SuperRare clone app we offer will help your users to showcase all the booming digital collectibles for bidding and trading. Our clone app is completely optimizable so you can alter the design, core functionalities, and other add-on modules based on your business necessities and concepts. Additionally, our SuperRare clone app is highly responsive and comes with high-end security aspects which helps you to provide a glitch-free environment to your users.
              </p>
              <div className='mobile-hide'>
              <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img width="530px" height="650px" src="https://coinsclone.mo.cloudinary.net/images/superrare/superrare-clone-app-development.png" alt="superrare clone app development" />
            </div>
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>

          <div className='mobile-on'>
              <ButtonComponent />
              </div>
        </div>
      </section>
    )
}

export default OurAndroid