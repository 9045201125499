import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            
            <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of </span>  Our SuperRare Clone Script </h2>
            <p className="pharagraph head">We at Coinsclone undergo the following procedures for Superrare clone script development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="490px" height="386px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/planning.png" alt="Development approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h3>Requirement Gathering</h3>
            <p className="pharagraph">Once you contact us with your idea, we will run it through our analysis. We are an experienced team of developers who can immediately assess the feasibility of the project. Once we have worked out the nitty-gritty, we can present you with our plan to use our SuperRare clone script to build your NFT platform.
            </p>
            <br />
            <h3>Planning </h3>
            <p className="pharagraph">We will do this in the form of wireframing and prototyping. This will give you a visual idea of how your web interface for your mobile interface will flow and how your users and digital artists will use and interact on your platform.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h3>Designing</h3>
            <p className="pharagraph">Based on the analysis and the approval of the prototype, we will design and develop your NFT platform using our Superrare clone script. This includes coding and UI/UX design as per your preferences and branding needs.</p>
            <br />
            <h3>Development</h3>
            <p  className="pharagraph">Once you have your representation, we will get down to building the application. This includes customizing the SuperRare clone script, integrating it with the overall application that we build and making the necessary database connections. It also includes building a highly attractive user interface.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-right order1">
            <img width="429px" height="431px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/designing.png" alt="Designing" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="321px" height="282px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/testing.png" alt="Testing and Deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h3>Testing</h3>
            <p  className="pharagraph">This is just to make sure that everything works the way it should and there are no kinks.
            </p>
            <br />
            <h3>Deployment</h3>
            <p className="pharagraph mb-0">Once we have programmed and tested your platform and social network, we will install it on the web. If we have built a mobile app, we will also deploy the mobile app in the appropriate app store.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach