import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose </span>
                Coinsclone For SuperRare Clone Script?</h2>
                <p className="pharagraph head">
              </p> 
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side">
              <img  width="501px" height="624px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/why-choose.png" alt="Why Choose our Superrare Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinslcone is recognized as the Best <span className='learn-more'><a href='https://www.coinsclone.com/white-label-nft-marketplace/'>White Label NFT Marketplace Development Company</a></span> in the Crypto Industry. If you want to run an NFT Marketplace that makes lots of money for you? And also want to add a social networking twist to create and maintain buzz around your business and consequently, generate more revenue? Then our SuperRare clone script is the best option for you. Our clone script gives you all the needed features you’re ever going to need. Whether you want to run your marketplace on a web-based interface or a mobile app, our premium SuperRare clone script can easily jell into every kind of environment.
              </p>
              <p className="pharagraph">Our SuperRare clone script comes with robust security features and top-notch technical features. Besides, our clone script supports multiple digital collectibles in the name of NFTs such as videos, graphic files, animations,3D images, audio, and more. Once these digital assets are converted into NFTs, your users can easily trade non-fungible tokens between different wallets.
              </p>
              <p className="pharagraph mb-0">Another reason to buy your SuperRare clone script from us is that we are one of the pioneers in the blockchain field and have a pool of skilled blockchain developers. We know this script from the inside out. We have deployed multiple NFT Marketplace platforms using our bug-free SuperRare clone script. So far we have successfully delivered 350+ crypto and blockchain projects to our reputed global clients.
              </p> 
            </div> 
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose