import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'



const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <div className='pt-100 mobile-how-our'>
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

          <h2 className="heading-h2"><span className="heading-h3 bluecolor">How our </span> SuperRare Clone Script work?</h2>
        </div>
        { isDesktop && (
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1200.25" height={1125} viewBox="0 0 1200.25 1125">
          <defs>
            <linearGradient id="linear-gradient" x1="0.901" y1="0.74" x2="0.273" y2="0.369" gradientUnits="objectBoundingBox">
              <stop offset={0} />
              <stop offset={1} stopColor="#28282a" />
            </linearGradient>
          </defs>
          <g id="Flow" transform="translate(-377.75 -4850.918)">
            <g id="Ellipse_1376" data-name="Ellipse 1376" transform="translate(1228.413 4877)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={106} cy={106} r={106} stroke="none" />
              <circle cx={106} cy={106} r={105} fill="none" />
            </g>
            <g id="Linr" transform="translate(0 -88)">
              <line id="Line_127" data-name="Line 127" x2={67} transform="translate(652.5 5072.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_128" data-name="Line 128" y1={198} transform="translate(719.5 5072.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_129" data-name="Line 129" y1={514} transform="translate(719 5334.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <g id="Group_28361" data-name="Group 28361">
                <line id="Line_230" data-name="Line 230" y1={86} transform="translate(812 5900.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_231" data-name="Line 231" x2="131.25" transform="translate(942 6026.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_232" data-name="Line 232" y2={290} transform="translate(1073.25 5736.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_233" data-name="Line 233" x2={207} transform="translate(1073.25 5736.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_236" data-name="Line 236" x2={172} transform="translate(910.25 5503.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_237" data-name="Line 237" y1={27} transform="translate(1082.25 5503.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_238" data-name="Line 238" y1={27} transform="translate(1082.25 5573.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_239" data-name="Line 239" x2={163} transform="translate(1082.25 5600.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_234" data-name="Line 234" x2={122} transform="translate(1073.25 5893.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_235" data-name="Line 235" y1={83} transform="translate(1362.25 5910.418)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              </g>
            </g>
            <g id="_1" data-name={1} transform="translate(-11.587 1388)">
              <g id="Login" transform="translate(-211.226 3284.492)">
                <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(621.226 204.508)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <circle cx={106} cy={106} r={106} stroke="none" />
                  <circle cx={106} cy={106} r={105} fill="none" />
                </g>
                <g id="User_Login" data-name="User Login" transform="translate(258.326 158.476)">
                  <g id="Group_23234" data-name="Group 23234" transform="translate(342.237 94.756)">
                    <g id="Group_23233" data-name="Group 23233" style={{ isolation: 'isolate' }}>
                      <path id="Path_13507" data-name="Path 13507" d="M409.5,103.631,381,87.178a.331.331,0,0,0-.344-.018L342.42,109.41a.326.326,0,0,1,.344.02l28.5,16.453a2.594,2.594,0,0,0,2.617.245l38.236-22.249A2.585,2.585,0,0,1,409.5,103.631Z" transform="translate(-341.953 9.224)" fill="#bababa" />
                      <path id="Path_13508" data-name="Path 13508" d="M358.179,102.334l-.132,34.5c-.005,2.393-.851,4.074-2.212,4.867l38.236-22.252c1.361-.79,2.207-2.472,2.214-4.867l.133-34.5Z" transform="translate(-321.186 -1.666)" fill="#dbdbdb" />
                      <path id="Path_13509" data-name="Path 13509" d="M382.092,69.215l-38.237,22.25L369.209,106.1l38.237-22.25Z" transform="translate(-339.732 -18.493)" fill="#f4f4f4" />
                      <path id="Path_13510" data-name="Path 13510" d="M343.949,72.562l-.094,48.163,38.237-22.25.093-48.163Z" transform="translate(-339.732 -47.754)" fill="#dbdbdb" />
                      <path id="Path_13511" data-name="Path 13511" d="M384.3,51.544a2.045,2.045,0,0,0-.344-.5,6.2,6.2,0,0,0-1.5-1.175c-.736-.426-1.475-.675-1.779-.5L342.441,71.615c.306-.176,1.042.074,1.779.5a6.218,6.218,0,0,1,1.5,1.175,2.072,2.072,0,0,1,.341.5.791.791,0,0,1,.079.324l38.236-22.249A.788.788,0,0,0,384.3,51.544Z" transform="translate(-341.92 -49.309)" fill="#f4f4f4" />
                      <path id="Path_13512" data-name="Path 13512" d="M371.715,123.4,346.36,108.762l.094-48.163c0-.6-.958-1.45-1.919-2s-1.921-.81-1.921-.214l-.1,50.349a4.233,4.233,0,0,0,1.9,3.325L371.7,127.814a8.144,8.144,0,0,1,3.687,6.429l-.13,34.5c-.008,2.347-1.674,3.335-3.715,2.158l-28.5-16.453c-1.073-.622-1.085,3.791-.013,4.411l28.5,16.453c4.151,2.4,7.552.459,7.565-4.349l.133-34.5A16.623,16.623,0,0,0,371.715,123.4Z" transform="translate(-342.237 -35.791)" fill="#c6c6c6" />
                      <path id="Path_13513" data-name="Path 13513" d="M398.246,82.324a18.264,18.264,0,0,0-2.928-4.6,13.284,13.284,0,0,0-3.277-2.759L353.806,97.209a13.378,13.378,0,0,1,3.274,2.76,18.163,18.163,0,0,1,2.928,4.607,14.272,14.272,0,0,1,1.312,5.692l38.238-22.249A14.335,14.335,0,0,0,398.246,82.324Z" transform="translate(-324.327 -9.6)" fill="#f4f4f4" />
                    </g>
                    <path id="Path_13514" data-name="Path 13514" d="M393.329,76.043,355.091,98.292a18.162,18.162,0,0,1,2.928,4.607l38.238-22.252A18.263,18.263,0,0,0,393.329,76.043Z" transform="translate(-322.338 -7.924)" fill="#f4f4f4" style={{ mixBlendMode: 'screen', isolation: 'isolate' }} />
                  </g>
                  <g id="Group_23240" data-name="Group 23240" transform="translate(345.473 19.95)">
                    <g id="Group_23235" data-name="Group 23235" transform="translate(53.552 192.499)">
                      <path id="Path_13515" data-name="Path 13515" d="M380.52,126.075l-.772-.961c-4.347-8.48-4.724-18.384-4.676-27.913,0-.589.031-1.129.041-1.7a32.006,32.006,0,0,1-9.165.782c.884,3.5,1.738,7.086,1.182,10.223-.976,5.486-1.228,9.124.785,14.975a16.275,16.275,0,0,0,3.172,5.634,14.481,14.481,0,0,0,4.956,2.742,4.932,4.932,0,0,0,2.026.482,3.652,3.652,0,0,0,2.691-1.809C381.284,127.726,381.435,126.353,380.52,126.075Z" transform="translate(-362.32 -95.499)" fill="#f4bd98" />
                      <path id="Path_13516" data-name="Path 13516" d="M366.433,115.757l2.18-1.308v-6.6l-2.865-3.176Z" transform="translate(-362.629 -81.301)" fill="#3e5293" />
                      <path id="Path_13517" data-name="Path 13517" d="M366.022,121.812l2.306,1.457.059-9.285a5.389,5.389,0,0,1,1.149,2.844c.691,3.86,1.72,9.384,3.463,10.3,4.609,2.428,11.127,1.9,12.269.668a6.2,6.2,0,0,0-3.315-9.369c-2.444,1.99-5.328,2.433-7.718.382s-3.6-5.155-4.362-8.212a24.058,24.058,0,0,1-.538-10.781c-2.283,1.554-4.813,3.557-4.811,6.317a12.107,12.107,0,0,0,.64,3.025C366.236,113.215,366.022,121.812,366.022,121.812Z" transform="translate(-364.524 -88.813)" fill="#5e71ba" />
                    </g>
                    <g id="Group_23236" data-name="Group 23236" transform="translate(78.413 184.299)">
                      <path id="Path_13518" data-name="Path 13518" d="M391.21,113.8l-.861-.882c-3.934-6.146-5.626-13.344-6.594-20.641-.025.008-.046.02-.074.028q-1.5.432-3,.859l-.008-.053c-.543.13-1.108.25-1.735.357a6,6,0,0,1-1.169.076,16.087,16.087,0,0,1-1.888.061,19.081,19.081,0,0,1,.127,1.993c0,6.187.168,9.981,2.734,15.612a11.945,11.945,0,0,0,3.177,4.525,14.536,14.536,0,0,0,5.2,2.255,4.944,4.944,0,0,0,2.061.285,3.64,3.64,0,0,0,2.5-2.059C392.127,115.374,392.148,113.99,391.21,113.8Z" transform="translate(-371.804 -92.281)" fill="#f4bd98" />
                      <path id="Path_13519" data-name="Path 13519" d="M377.478,109.448l2.045-1.51-.632-6.571-3.156-2.888Z" transform="translate(-372.03 -82.687)" fill="#3e5293" />
                      <path id="Path_13520" data-name="Path 13520" d="M377.3,115.8l2.433,1.231-.831-9.247a5.389,5.389,0,0,1,1.417,2.719c1.055,3.776,2.609,9.178,4.431,9.924,4.821,1.977,11.26.826,12.276-.51a6.191,6.191,0,0,0-4.2-9.007c-2.24,2.214-5.068,2.93-7.644,1.119s-4.077-4.79-5.129-7.759a24.091,24.091,0,0,1-1.567-10.681c-2.123,1.768-4.449,4-4.184,6.752a12.238,12.238,0,0,0,.925,2.945C376.684,107.218,377.3,115.8,377.3,115.8Z" transform="translate(-374.281 -90.264)" fill="#5e71ba" />
                    </g>
                    <path id="Path_13521" data-name="Path 13521" d="M403.047,106.957a32.826,32.826,0,0,0,2.365,9.542c2.28,5.583,5.216,12.7,5.216,18.728.583,1.312,7.254,1.243,8.691-.637a93.373,93.373,0,0,1-1.269-9.4,153.8,153.8,0,0,1-.372-20.275,48.985,48.985,0,0,0-.324-10.314c-.507-3.047-2.732-8.566-6.834-11.726-2.186-1.684-31.32-15.352-38.941-16.019-4.772-.418-14.822-.538-14.475,6.245.138,2.714-4.986,6.564-3.616,8.918.856,1.468,8.2,4.821,15.872,8.166,5.361,2.337,22.193,7.4,24.163,7.947C400.57,100.106,402.682,102.837,403.047,106.957Z" transform="translate(-328.417 52.41)" fill="#28282a" />
                    <path id="Path_13522" data-name="Path 13522" d="M390,117.306a32.777,32.777,0,0,0,1.442,9.726c1.735,5.776,3.975,13.138,3.4,19.141.456,1.363,7.1,1.931,8.712.2a93.918,93.918,0,0,1-.362-9.479,153.969,153.969,0,0,1,1.567-20.219c.533-4.067.059-17.808-5.012-22.624-2-1.9-29.478-17.072-37-18.465-4.711-.874-9.209-9.155-13.752-4.1-1.9,2.1-1.264,11.723-.125,14.2.711,1.544,1.891,5.025,9.2,9.089,5.111,2.839,21.4,12.065,23.289,12.847C388.632,110.628,390.031,113.171,390,117.306Z" transform="translate(-336.884 57.308)" fill="#28282a" />
                    <path id="Path_13523" data-name="Path 13523" d="M377.048,48.714l-3.32-8.977-12.317,2.984-3.014,4.5,1.865,7.145s.907,2.382,10.218-1.014C377.3,50.862,377.048,48.714,377.048,48.714Z" transform="translate(-320.456 10.68)" fill="#4a5d99" />
                    <path id="Path_13524" data-name="Path 13524" d="M419.284,51.953c-.594-1.712-8.9-2.785-14.131,4.6-1.8,2.54-2.247,4.324-2.851,7.31-3.142,1.557-19.632,10.681-19.632,10.681s-10.5-37.28-18.534-37.265c-16.529.031,6.108,43.1,11.425,50.142a1.4,1.4,0,0,0,1.852.354c3.842-2.306,17.946-10.918,28.839-19.536,2.436-1.226,6.1-6.961,8.5-11.3C414.748,56.937,420.3,54.883,419.284,51.953Z" transform="translate(-320.774 6.874)" fill="#f9cfb2" />
                    <path id="Path_13525" data-name="Path 13525" d="M366.421,62.038C361.465,51.285,356.9,46.3,358.38,39.86c.843-3.684,3.847-5.891,8.76-2.831a17.486,17.486,0,0,1,4.938,4.964c3.111,4.762,5.417,13.076,5.417,13.076C375.5,53.917,369.988,60.2,366.421,62.038Z" transform="translate(-320.92 4.25)" fill="#5e71ba" />
                    <g id="Group_23237" data-name="Group 23237">
                      <path id="Path_13526" data-name="Path 13526" d="M370.636,32.921a1.965,1.965,0,0,0,.2-2.907c-.583-.7-1.516-.716-2.26-1.236-1.19-.833-2.415-2.171-3.684-2.879a4.89,4.89,0,0,0-2.11-3.682c-7.937-6.681-23.827,1.809-18.025,20.173,1.677,5.3,6.793,13.8,6.283,25.536,3.825-2.331,6.948-10.842,7.394-14.852.459-4.107-.224-7.647-.041-11.777C358.508,38.629,369.571,34.088,370.636,32.921Z" transform="translate(-343.507 -19.95)" fill="#54361f" />
                      <path id="Path_13527" data-name="Path 13527" d="M362.1,28.294a12.715,12.715,0,0,0-2.222-2.25c-1.182-1.057-2.515-2.143-3.83-3.31-2.571-2.326-7.8,3.8-5.967,5.891.283.354,3.5,2.642,3.5,2.642,1.019,1.478,6.627,1.284,7.932.155A2.148,2.148,0,0,0,362.1,28.294Z" transform="translate(-333.924 -16.444)" fill="#5e71ba" />
                    </g>
                    <g id="Group_23239" data-name="Group 23239" transform="translate(7.739 7.843)">
                      <g id="_2" data-name={2} transform="translate(0 10.866)">
                        <path id="Path_13528" data-name="Path 13528" d="M393.784,62.875c.555-2.275-1.086-5.453-2.861-8.064a66.18,66.18,0,0,1-6.38-11.509c-2.153-5.114-4.069-8.273-6.148-7.6-.772.25-25.6,13.581-26.617,14.085a10.723,10.723,0,0,0-5.022,6.011c-1.144,2.912,2.637,14.422,4.663,33.394a120.569,120.569,0,0,1-2.1,36.1,1.923,1.923,0,0,0,.976,2.143c8.031,4.084,35.073,3.391,38.865-11.018a100.749,100.749,0,0,1-2.145-16.139C385.753,82.013,391.674,71.533,393.784,62.875Z" transform="translate(-346.544 -14.415)" fill="#5e71ba" />
                        <path id="Path_13529" data-name="Path 13529" d="M372.973,44.751c-1.348-4.579-1.832-7.038-3.947-8a3.458,3.458,0,0,0-1.037-.255c-4.744,2.5-12.406,6.594-17.439,9.28l-.005.028a1.422,1.422,0,0,0,.354.624A52.932,52.932,0,0,0,361,55.188C366.006,58.064,377.22,59.191,372.973,44.751Z" transform="translate(-340.351 -13.044)" fill="#f9cfb2" />
                        <g id="Group_23238" data-name="Group 23238" transform="translate(10.829)">
                          <path id="Path_13530" data-name="Path 13530" d="M351.416,35.6c.874-1.236,14.022-3.56,16.988,7.055s-2.558,12.327-2.558,12.327c-4.941,1.98-11.02-3.608-13.489-6.273a1.378,1.378,0,0,1-.39-.922C351.834,45.411,351.306,35.757,351.416,35.6Z" transform="translate(-349.854 -16.148)" fill="#f2c3a0" />
                          <path id="Path_13531" data-name="Path 13531" d="M371.706,53.914c-.6,2.209-1.266,4.885-3.4,5.55-2.568.8-9.308-2.321-12.544-6.441-3.776-4.808-5.883-12-4.579-15.393.973-2.528,5.364-4.513,7.675-6.288a21.083,21.083,0,0,1,5.407-2.81c5.453-2.1,10.246-1.9,10.4,3.58a72.3,72.3,0,0,1-.125,8.408C373.741,49.292,372.577,50.711,371.706,53.914Z" transform="translate(-350.794 -27.293)" fill="#f9cfb2" />
                        </g>
                      </g>
                      <path id="Path_13532" data-name="Path 13532" d="M379.812,40.782c.647-6.64-.316-13.561-8.732-16.715-7.782-2.915-17.332.54-21.342,7.476a12.7,12.7,0,0,0-1.524,6.813c.558,15.031,6.648,12.169,8.551,23.533,0-2.637.288-5.267.232-7.9-.074-3.539-2.7-9.163.951-8.543,2.061.349,2.222,4.742,2.257,6.8.023,1.271,1.508,1.779,1.865.915,1.139-2.757-.6-6.561,1.577-8.918,4.586-4.969,10.719-6.811,13.624-6.136a1.421,1.421,0,0,1,1.083,1.343,79.547,79.547,0,0,1,.054,8.737A32.8,32.8,0,0,0,379.812,40.782Z" transform="translate(-343.975 -23.029)" fill="#54361f" />
                    </g>
                    <path id="Path_13533" data-name="Path 13533" d="M364.725,55.437,362.3,46.178l-12.551,1.753-3.44,4.179,1.149,7.292s.67,2.461,10.268,0C364.763,57.6,364.725,55.437,364.725,55.437Z" transform="translate(-339.162 20.65)" fill="#4a5d99" />
                    <path id="Path_13534" data-name="Path 13534" d="M362.539,60.444c-.861-3.5-5.114-17.584-9.127-18.641-1.193-.313-2.775-.395-3.577.54-11.522,13.4,9.66,46.774,12.366,48.7l6.635-8.7S364.445,68.162,362.539,60.444Z" transform="translate(-338.984 13.545)" fill="#f9cfb2" />
                    <path id="Path_13535" data-name="Path 13535" d="M365.385,62.979c-2.145-11.6-3.363-17.846-8.465-20.884-3.5-2.087-9.835-.4-10.982,9.56-.29,2.525.673,9.889,2.184,15.288C349.516,61.536,362.684,58.8,365.385,62.979Z" transform="translate(-339.822 13.109)" fill="#5e71ba" />
                  </g>
                  <g id="Group_23241" data-name="Group 23241" transform="translate(357.385 104.546)">
                    <path id="Path_13536" data-name="Path 13536" d="M443.229,167.814l-55.68-32.274-.22-77.654,55.68,32.274Z" transform="translate(-287.582 -45.822)" fill="#c6c6c6" />
                    <path id="Path_13537" data-name="Path 13537" d="M404.1,195.005l-55.68-32.274-.241-85.217,55.68,32.274Z" transform="translate(-348.182 -15.437)" fill="#c6c6c6" />
                    <path id="Path_13538" data-name="Path 13538" d="M403.862,147.5l-55.68-32.274L455.7,53.151l55.68,32.274Z" transform="translate(-348.182 -53.151)" fill="#f4f4f4" />
                    <path id="Path_13539" data-name="Path 13539" d="M477.555,65.817l.242,85.217-7.8,4.5-.22-77.654L377.87,130.946l.22,77.654-7.815,4.512-.241-85.217Z" transform="translate(-314.354 -33.543)" fill="#dbdbdb" />
                  </g>
                  <g id="Group_23246" data-name="Group 23246" transform="translate(375.774 152.708)">
                    <g id="Group_23245" data-name="Group 23245">
                      <path id="Path_13540" data-name="Path 13540" d="M363.684,72.3,391.939,88.72c.762.443.629,3.753-.413,4.355L363.269,76.656C364.311,76.054,364.444,72.742,363.684,72.3Z" transform="translate(-343.216 -71.668)" fill="#41568c" />
                      <path id="Path_13541" data-name="Path 13541" d="M375.443,74.01,403.7,90.431,383.661,102,355.4,85.579Z" transform="translate(-355.391 -69.023)" fill="#5e71ba" />
                      <path id="Path_13542" data-name="Path 13542" d="M383.661,94.972,355.4,78.55v.389L383.662,95.36Z" transform="translate(-355.391 -61.994)" fill="#41568c" />
                      <g id="Group_23242" data-name="Group 23242" transform="translate(1.324 12.085)">
                        <path id="Path_13543" data-name="Path 13543" d="M385.714,78.732c1.164-.673,1.175,3.5.01,4.174L366.629,93.931l-.01-4.176Z" transform="translate(-339.354 -73.911)" fill="#fffffa" />
                        <path id="Path_13544" data-name="Path 13544" d="M383.183,92.64l.012,4.176L355.93,80.972l-.012-4.176Z" transform="translate(-355.919 -76.796)" fill="#d6d6c9" />
                      </g>
                      <g id="Group_23243" data-name="Group 23243" transform="translate(28.258 16.422)">
                        <path id="Path_13545" data-name="Path 13545" d="M366.489,90.337v.39l.25-.145,19.79-11.425c1.205-.7,1.218,3.631.013,4.327L366.751,94.91l-.25.143v.39l20.392-11.772c1.228-.711,1.213-5.815-.015-5.106Z" transform="translate(-366.489 -78.498)" fill="#4f63a3" />
                      </g>
                      <path id="Path_13546" data-name="Path 13546" d="M375.788,72.12a.373.373,0,0,1,.392-.02l28.255,16.422a.363.363,0,0,0-.39.02l-20.389,11.772L355.4,83.891Z" transform="translate(-355.399 -72.053)" fill="#5e71ba" />
                      <g id="Group_23244" data-name="Group 23244" transform="translate(0 11.84)">
                        <path id="Path_13547" data-name="Path 13547" d="M383.656,93.12,355.4,76.7v.389l28.257,16.421Z" transform="translate(-355.399 -76.699)" fill="#41568c" />
                      </g>
                    </g>
                    <path id="Path_13548" data-name="Path 13548" d="M366.539,82.554l7.233-4.176.643.371-7.233,4.176Z" transform="translate(-338.153 -62.261)" fill="#f9e6d2" />
                    <path id="Path_13549" data-name="Path 13549" d="M371.8,80.782l3.957-2.285.352.2-3.957,2.285Z" transform="translate(-330.007 -62.076)" fill="#f9e6d2" />
                    <path id="Path_13550" data-name="Path 13550" d="M364.853,84.016,376.305,77.4l.722.417-11.452,6.612Z" transform="translate(-340.764 -63.769)" fill="#f9e6d2" />
                    <path id="Path_13551" data-name="Path 13551" d="M364.028,83.54l11.452-6.612.722.417L364.75,83.957Z" transform="translate(-342.04 -64.506)" fill="#f9e6d2" />
                    <path id="Path_13552" data-name="Path 13552" d="M376.882,75.987l-14.534,8.391,10.144,5.858,14.531-8.391ZM372.474,89.8,363.1,84.388,376.9,76.425l9.369,5.409Z" transform="translate(-344.642 -65.962)" fill="#f9e6d2" />
                  </g>
                  <g id="Group_23262" data-name="Group 23262" transform="translate(452.411 99.024)">
                    <g id="Group_23251" data-name="Group 23251" transform="translate(0 13.679)">
                      <g id="Group_23250" data-name="Group 23250">
                        <path id="Path_13553" data-name="Path 13553" d="M393.761,56.6l28.258,16.419c.759.443.627,3.753-.415,4.357L393.346,60.955C394.388,60.353,394.521,57.041,393.761,56.6Z" transform="translate(-373.293 -55.968)" fill="#b88c33" />
                        <path id="Path_13554" data-name="Path 13554" d="M405.521,58.309,433.778,74.73,413.739,86.3,385.482,69.879Z" transform="translate(-385.467 -53.323)" fill="#e6b74a" />
                        <path id="Path_13555" data-name="Path 13555" d="M413.739,79.271,385.482,62.85v.389L413.74,79.66Z" transform="translate(-385.467 -46.294)" fill="#b88c33" />
                        <g id="Group_23247" data-name="Group 23247" transform="translate(1.325 12.085)">
                          <path id="Path_13556" data-name="Path 13556" d="M415.791,63.031c1.164-.673,1.175,3.5.013,4.176L396.709,78.23l-.013-4.176Z" transform="translate(-369.432 -58.21)" fill="#fffffa" />
                          <path id="Path_13557" data-name="Path 13557" d="M413.26,76.939l.012,4.176L386.008,65.271,386,61.095Z" transform="translate(-385.996 -61.095)" fill="#d6d6c9" />
                        </g>
                        <g id="Group_23248" data-name="Group 23248" transform="translate(28.257 16.421)">
                          <path id="Path_13558" data-name="Path 13558" d="M396.566,74.636v.39l.252-.145,19.788-11.425c1.208-.7,1.22,3.631.013,4.329l-19.79,11.425-.25.143,0,.39L416.97,67.97c1.228-.711,1.213-5.815-.015-5.106Z" transform="translate(-396.566 -62.797)" fill="#cfa03e" />
                        </g>
                        <path id="Path_13559" data-name="Path 13559" d="M405.865,56.418a.379.379,0,0,1,.392-.02L434.515,72.82a.373.373,0,0,0-.392.02L413.733,84.612,385.476,68.19Z" transform="translate(-385.476 -56.352)" fill="#e6b74a" />
                        <g id="Group_23249" data-name="Group 23249" transform="translate(0.001 11.839)">
                          <path id="Path_13560" data-name="Path 13560" d="M413.733,77.42,385.476,61v.389l28.257,16.421Z" transform="translate(-385.476 -60.998)" fill="#b88c33" />
                        </g>
                      </g>
                      <path id="Path_13561" data-name="Path 13561" d="M396.617,66.853l7.233-4.176.643.371-7.234,4.176Z" transform="translate(-368.23 -46.561)" fill="#f9e6d2" />
                      <path id="Path_13562" data-name="Path 13562" d="M401.879,65.081l3.957-2.285.352.2-3.957,2.285Z" transform="translate(-360.083 -46.376)" fill="#f9e6d2" />
                      <path id="Path_13563" data-name="Path 13563" d="M394.93,68.315,406.382,61.7l.722.417-11.452,6.612Z" transform="translate(-370.841 -48.069)" fill="#f9e6d2" />
                      <path id="Path_13564" data-name="Path 13564" d="M394.106,67.839l11.452-6.612.722.417-11.452,6.612Z" transform="translate(-372.117 -48.805)" fill="#f9e6d2" />
                      <path id="Path_13565" data-name="Path 13565" d="M406.957,60.287l-14.531,8.391,10.141,5.855L417.1,66.142ZM402.549,74.1l-9.369-5.412,13.8-7.963,9.369,5.409Z" transform="translate(-374.717 -50.261)" fill="#f9e6d2" />
                    </g>
                    <g id="Group_23256" data-name="Group 23256" transform="translate(0.145 6.828)">
                      <g id="Group_23255" data-name="Group 23255">
                        <path id="Path_13566" data-name="Path 13566" d="M393.818,53.911l28.258,16.422c.759.441.627,3.753-.415,4.355L393.4,58.266C394.445,57.664,394.578,54.354,393.818,53.911Z" transform="translate(-373.35 -53.28)" fill="#64398f" />
                        <path id="Path_13567" data-name="Path 13567" d="M405.578,55.62l28.257,16.421L413.8,83.611,385.539,67.19Z" transform="translate(-385.524 -50.634)" fill="#8f53bd" />
                        <path id="Path_13568" data-name="Path 13568" d="M413.8,76.582,385.539,60.161v.389L413.8,76.971Z" transform="translate(-385.524 -43.605)" fill="#64398f" />
                        <g id="Group_23252" data-name="Group 23252" transform="translate(1.325 12.085)">
                          <path id="Path_13569" data-name="Path 13569" d="M415.848,60.342c1.164-.673,1.175,3.5.013,4.176L396.766,75.541l-.013-4.176Z" transform="translate(-369.489 -55.522)" fill="#fffffa" />
                          <path id="Path_13570" data-name="Path 13570" d="M413.317,74.25l.012,4.176L386.065,62.582l-.012-4.176Z" transform="translate(-386.053 -58.406)" fill="#d6d6c9" />
                        </g>
                        <g id="Group_23253" data-name="Group 23253" transform="translate(28.257 16.422)">
                          <path id="Path_13571" data-name="Path 13571" d="M396.623,71.948v.387l.252-.145,19.788-11.425c1.208-.7,1.221,3.633.013,4.329l-19.79,11.425-.25.145,0,.387L417.028,65.28c1.228-.708,1.213-5.815-.015-5.1Z" transform="translate(-396.623 -60.108)" fill="#7946a6" />
                        </g>
                        <path id="Path_13572" data-name="Path 13572" d="M405.922,53.731a.367.367,0,0,1,.392-.02L434.572,70.13a.368.368,0,0,0-.392.023L413.79,81.925,385.533,65.5Z" transform="translate(-385.533 -53.663)" fill="#8f53bd" />
                        <g id="Group_23254" data-name="Group 23254" transform="translate(0.001 11.839)">
                          <path id="Path_13573" data-name="Path 13573" d="M413.79,74.731,385.533,58.31V58.7L413.791,75.12Z" transform="translate(-385.533 -58.31)" fill="#64398f" />
                        </g>
                      </g>
                      <path id="Path_13574" data-name="Path 13574" d="M396.674,64.165l7.233-4.176.643.371-7.233,4.176Z" transform="translate(-368.287 -43.872)" fill="#f9e6d2" />
                      <path id="Path_13575" data-name="Path 13575" d="M401.936,62.393l3.957-2.285.352.2L402.288,62.6Z" transform="translate(-360.14 -43.687)" fill="#f9e6d2" />
                      <path id="Path_13576" data-name="Path 13576" d="M394.987,65.626l11.452-6.612.722.417-11.452,6.612Z" transform="translate(-370.898 -45.38)" fill="#f9e6d2" />
                      <path id="Path_13577" data-name="Path 13577" d="M394.163,65.151l11.452-6.612.722.417-11.452,6.612Z" transform="translate(-372.174 -46.117)" fill="#f9e6d2" />
                      <path id="Path_13578" data-name="Path 13578" d="M407.014,57.6l-14.531,8.391,10.141,5.855,14.534-8.391Zm-4.408,13.81L393.237,66l13.795-7.965,9.369,5.409Z" transform="translate(-374.774 -47.573)" fill="#f9e6d2" />
                    </g>
                    <g id="Group_23261" data-name="Group 23261" transform="translate(0.19 0)">
                      <g id="Group_23260" data-name="Group 23260">
                        <path id="Path_13579" data-name="Path 13579" d="M393.835,51.232l28.257,16.419c.759.443.627,3.753-.415,4.355L393.42,55.587C394.465,54.983,394.6,51.673,393.835,51.232Z" transform="translate(-373.369 -50.599)" fill="#ab3a3a" />
                        <path id="Path_13580" data-name="Path 13580" d="M405.6,52.941l28.257,16.421-20.039,11.57L385.556,64.51Z" transform="translate(-385.543 -47.954)" fill="#da5353" />
                        <path id="Path_13581" data-name="Path 13581" d="M413.813,73.9,385.556,57.481v.389l28.257,16.421Z" transform="translate(-385.543 -40.925)" fill="#ab3a3a" />
                        <g id="Group_23257" data-name="Group 23257" transform="translate(1.324 12.085)">
                          <path id="Path_13582" data-name="Path 13582" d="M415.865,57.662c1.164-.67,1.177,3.5.013,4.176L396.783,72.864l-.013-4.176Z" transform="translate(-369.507 -52.843)" fill="#fffffa" />
                          <path id="Path_13583" data-name="Path 13583" d="M413.334,71.571l.012,4.176L386.082,59.9l-.012-4.176Z" transform="translate(-386.07 -55.727)" fill="#d6d6c9" />
                        </g>
                        <g id="Group_23258" data-name="Group 23258" transform="translate(28.256 16.422)">
                          <path id="Path_13584" data-name="Path 13584" d="M396.64,69.268l0,.387.25-.143L416.68,58.087c1.208-.7,1.221,3.631.013,4.327L396.9,73.839l-.25.145v.39L417.044,62.6c1.228-.708,1.213-5.812-.013-5.1Z" transform="translate(-396.64 -57.429)" fill="#c44343" />
                        </g>
                        <path id="Path_13585" data-name="Path 13585" d="M405.94,51.051a.368.368,0,0,1,.39-.02l28.258,16.422a.363.363,0,0,0-.39.02L413.806,79.244,385.551,62.822Z" transform="translate(-385.55 -50.984)" fill="#da5353" />
                        <g id="Group_23259" data-name="Group 23259" transform="translate(0 11.839)">
                          <path id="Path_13586" data-name="Path 13586" d="M413.807,72.051,385.551,55.63v.389L413.809,72.44Z" transform="translate(-385.551 -55.63)" fill="#ab3a3a" />
                        </g>
                      </g>
                      <path id="Path_13587" data-name="Path 13587" d="M396.691,61.485l7.233-4.176.643.371-7.233,4.176Z" transform="translate(-368.305 -41.193)" fill="#f9e6d2" />
                      <path id="Path_13588" data-name="Path 13588" d="M401.954,59.713l3.957-2.285.352.2-3.957,2.285Z" transform="translate(-360.158 -41.007)" fill="#f9e6d2" />
                      <path id="Path_13589" data-name="Path 13589" d="M395,62.947l11.452-6.612.722.417-11.452,6.612Z" transform="translate(-370.916 -42.7)" fill="#f9e6d2" />
                      <path id="Path_13590" data-name="Path 13590" d="M394.18,62.471l11.452-6.612.722.417L394.9,62.888Z" transform="translate(-372.192 -43.437)" fill="#f9e6d2" />
                      <path id="Path_13591" data-name="Path 13591" d="M407.031,54.918,392.5,63.309l10.141,5.858,14.534-8.391Zm-4.406,13.81-9.372-5.409,13.795-7.963,9.369,5.409Z" transform="translate(-374.793 -44.893)" fill="#f9e6d2" />
                    </g>
                  </g>
                  <g id="Group_23266" data-name="Group 23266" transform="translate(392.74 122.802)">
                    <g id="Group_23263" data-name="Group 23263" transform="translate(16.96 3.666)">
                      <path id="Path_13592" data-name="Path 13592" d="M403.283,65.089,369.1,84.952l10.2,5.89,34.18-19.863Z" transform="translate(-368.11 -56.593)" fill="#403d3b" />
                      <path id="Path_13593" data-name="Path 13593" d="M374.233,82.121l-5.13,7.213,34.18-19.863,5.13-7.213Z" transform="translate(-368.11 -60.975)" fill="#212020" />
                      <path id="Path_13594" data-name="Path 13594" d="M380.815,84.282l-5.389-13.91a1.452,1.452,0,0,0-.586-.731.429.429,0,0,0-.591.051L368.8,77.344a.892.892,0,0,0,0,.775,1.445,1.445,0,0,0,.589.731l10.837,6.258c.24.138.464.125.589-.054A.887.887,0,0,0,380.815,84.282Zm-11.109-6.235,5.129-7.213,5.073,13.1Z" transform="translate(-368.713 -49.687)" fill="#282726" />
                      <path id="Path_13595" data-name="Path 13595" d="M411.566,76.486l-5.387-13.91-.033-.076a1.507,1.507,0,0,0-.255-.4,1.2,1.2,0,0,0-.3-.252.479.479,0,0,0-.484-.046L370.928,81.666a.484.484,0,0,1,.484.043,1.148,1.148,0,0,1,.3.252,1.6,1.6,0,0,1,.257.4l.031.079,5.389,13.91a.887.887,0,0,1,0,.772.368.368,0,0,1-.107.1L411.457,77.36a.329.329,0,0,0,.107-.1A.892.892,0,0,0,411.566,76.486Z" transform="translate(-365.285 -61.755)" fill="#383534" />
                    </g>
                    <g id="Group_23265" data-name="Group 23265" style={{ isolation: 'isolate' }}>
                      <path id="Path_13596" data-name="Path 13596" d="M390.287,60.467l-1.264,1.343.385,1.111c.938-.545,1.371-1.035,1.264-1.343Z" transform="translate(-320.314 -60.082)" fill="#e0e0e0" />
                      <path id="Path_13597" data-name="Path 13597" d="M385.8,70.888s-23.666-.176-23.737-.387l.385,1.111c.071.211.4.336.973.336L386.183,72Z" transform="translate(-362.047 -44.549)" fill="#a0a0a0" />
                      <path id="Path_13598" data-name="Path 13598" d="M413.012,60.994,373.527,83.94l.384,1.111L413.4,62.1Z" transform="translate(-344.302 -59.267)" fill="#d9d9d9" />
                      <path id="Path_13599" data-name="Path 13599" d="M376.851,70l-5.471,1.666.382,1.111a13.05,13.05,0,0,0,5.473-1.666Z" transform="translate(-347.626 -45.327)" fill="#e0e0e0" />
                      <path id="Path_13600" data-name="Path 13600" d="M431.049,60.364l-22.761-.048a13.105,13.105,0,0,0-5.471,1.666L363.331,84.927c-1.58.92-1.707,1.674-.283,1.679l22.764.048a13.088,13.088,0,0,0,5.471-1.666l39.484-22.945C432.346,61.124,432.489,60.37,431.049,60.364Z" transform="translate(-362.057 -60.316)" fill="#b7b7b7" />
                      <path id="Path_13601" data-name="Path 13601" d="M428.427,60.665,405.6,60.614a8.646,8.646,0,0,0-4.367,1.172l-37.8,21.956a.243.243,0,0,0,.12.454L388,84.249a4.188,4.188,0,0,0,1.939-.52l38.653-22.448A.331.331,0,0,0,428.427,60.665Z" transform="translate(-360.123 -59.855)" fill="#191919" />
                      <path id="Path_13602" data-name="Path 13602" d="M411.619,60.822l-21.872-.046c-5.165,11.053-16.5,22.611-16.5,22.611a3.655,3.655,0,0,0,1.5-.464l37.035-21.51A.318.318,0,0,0,411.619,60.822Z" transform="translate(-344.737 -59.604)" opacity="0.5" fill="url(#linear-gradient)" style={{ mixBlendMode: 'screen', isolation: 'isolate' }} />
                      <g id="Group_23264" data-name="Group 23264" transform="translate(12.051 24.978)">
                        <path id="Path_13603" data-name="Path 13603" d="M370.479,70.127l-2.535-.008a.71.71,0,0,0-.359.1l-.752.436a.091.091,0,0,0,.046.171l2.563,0a.457.457,0,0,0,.222-.059l.859-.492C370.612,70.223,370.622,70.119,370.479,70.127Z" transform="translate(-366.787 -70.119)" fill="#d9d9d9" />
                        <path id="Path_13604" data-name="Path 13604" d="M366.877,70.8l.749-.436a.726.726,0,0,1,.362-.1l2.53.005h0c.089-.046.1-.15-.043-.143l-2.535-.008a.71.71,0,0,0-.359.1l-.752.436a.089.089,0,0,0,.038.166Z" transform="translate(-366.785 -70.119)" fill="#a0a0a0" />
                      </g>
                    </g>
                  </g>
                  <path id="Path_13605" data-name="Path 13605" d="M400.056,78.456c-.617-2.574-2.844-6.778-3.858-7.17-2.423-.933-8.86-1.643-12.633-1.957L359.55,57.608a6.355,6.355,0,0,0-6.635,8.7c.958.678,11.2,3.506,27.985,8.467,1.582.6,6.339,2.084,10.607,3.73,0,0,2.838,4.064,6.115,2.831C398.26,81.1,400.428,80,400.056,78.456Z" transform="translate(15.773 58.226)" fill="#f9cfb2" />
                </g>
              </g>
              <g id="User" transform="translate(-821 -8038.5)">
                <rect id="Rectangle_298" data-name="Rectangle 298" width={141} height={43} rx="21.5" transform="translate(1330 11719)" />
                <text id="User-2" data-name="User" transform="translate(1401 11745)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-15.414" y={0}>User</tspan></text>
              </g>
              <text id="Connect_the_Digital_Wallet" data-name="Connect the 
Digital Wallet" transform="translate(755.587 3619)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Connect the </tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={20}>Digital Wallet</tspan></tspan></text>
            </g>
            <g id="KYC" transform="translate(21 4397.476)">
              <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(513.75 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={372} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={370} height={60} rx={24} fill="none" />
              </g>
              <text id="Deposit_the_assets_inside_the_platform" data-name="Deposit the assets inside the platform" transform="translate(699.75 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-124.88" y={0}>Deposit the assets </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>inside the platform</tspan></text>
            </g>
            <g id="Deposit" transform="translate(-40.5 4781)">
              <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(571.25 604.918)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={376} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={374} height={60} rx={24} fill="none" />
              </g>
              <text id="Mint_the_digital_assets_into_NFT" data-name="Mint the digital assets into NFT" transform="translate(636.25 640.918)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Mint the digital </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>assets into NFT</tspan></text>
            </g>
            <g id="Order_Matching_Engine_matches_the_best_BID_ASK" data-name="Order Matching Engine matches  the best BID/ASK" transform="translate(-24 4995.628)">
              <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(571 604.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={345} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={343} height={60} rx={24} fill="none" />
              </g>
              <text id="Add_Info_Description_for_your_NFT" data-name="Add Info/Description for your NFT" transform="translate(744 639.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-113.743" y={0}>Add Info/Description</tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}> for your NFT</tspan></text>
            </g>
            <g id="Order_Matching_Engine_matches_the_best_BID_ASK-2" data-name="Order Matching Engine matches  the best BID/ASK" transform="translate(-24 5147.628)">
              <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(571 604.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={345} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={343} height={60} rx={24} fill="none" />
              </g>
              <text id="The_Minted_NFT_and_Listed" data-name="The Minted NFT and Listed" transform="translate(744 639.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-90.664" y={0}>The Minted NFT and Listed</tspan></text>
            </g>
            <g id="Admin" transform="translate(-57.777 -6703.812)">
              <rect id="Rectangle_298-2" data-name="Rectangle 298" width={230} height={43} rx="21.5" transform="translate(1330 11719)" />
              <text id="Admin-2" data-name="Admin" transform="translate(1401 11745)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-22.561" y={0}>Admin</tspan></text>
            </g>
            <g id="Admin-3" data-name="Admin" transform="translate(846.412 4711.235)">
              <g id="Group_23187" data-name="Group 23187" transform="translate(616.448 371.096)">
                <g id="Group_23186" data-name="Group 23186">
                  <g id="Group_23185" data-name="Group 23185">
                    <path id="Path_13479" data-name="Path 13479" d="M550.822,260.167s5.807,9.845,8.261,11.455c3.327,2.181,1.241,4.39-.34,4.39s-9.654.58-11.432-1.439-2.66-8.068-4.315-9.651-3.365-7.87.806-9.4C547.486,254.171,550.822,260.167,550.822,260.167Z" transform="translate(-541.202 -255.323)" fill="#6069a8" />
                  </g>
                </g>
              </g>
              <g id="Group_23190" data-name="Group 23190" transform="translate(618.767 371.294)">
                <g id="Group_23189" data-name="Group 23189">
                  <g id="Group_23188" data-name="Group 23188">
                    <path id="Path_13480" data-name="Path 13480" d="M547.031,255.727s6.651,11.53,7.8,13.236-2.159,1.7-4.208.888-7.2-9.076-7.939-10.584a5.555,5.555,0,0,1-.156-3.855Z" transform="translate(-542.246 -255.412)" fill="#202338" />
                  </g>
                </g>
              </g>
              <g id="Group_23193" data-name="Group 23193" transform="translate(616.815 377.813)">
                <g id="Group_23192" data-name="Group 23192">
                  <g id="Group_23191" data-name="Group 23191">
                    <path id="Path_13481" data-name="Path 13481" d="M541.367,258.348l1.535,11.822.813.035-.254-8.5Z" transform="translate(-541.367 -258.348)" fill="#6069a8" />
                  </g>
                </g>
              </g>
              <g id="Group_23196" data-name="Group 23196" transform="translate(558.833 371.132)">
                <g id="Group_23195" data-name="Group 23195">
                  <g id="Group_23194" data-name="Group 23194">
                    <path id="Path_13482" data-name="Path 13482" d="M524.9,260.147s5.807,9.845,8.259,11.455c3.329,2.181,1.244,4.39-.337,4.39s-9.654.58-11.432-1.439-2.661-8.068-4.315-9.651-3.42-8.035.8-9.4C521.585,254.3,524.9,260.147,524.9,260.147Z" transform="translate(-515.258 -255.339)" fill="#6069a8" />
                  </g>
                </g>
              </g>
              <g id="Group_23199" data-name="Group 23199" transform="translate(561.174 371.294)">
                <g id="Group_23198" data-name="Group 23198">
                  <g id="Group_23197" data-name="Group 23197">
                    <path id="Path_13483" data-name="Path 13483" d="M521.448,255.752s6.3,11.506,7.446,13.211-2.156,1.7-4.2.888-7.2-9.076-7.939-10.584a5.554,5.554,0,0,1-.16-3.855Z" transform="translate(-516.312 -255.412)" fill="#202338" />
                  </g>
                </g>
              </g>
              <g id="Group_23202" data-name="Group 23202" transform="translate(559.223 377.813)">
                <g id="Group_23201" data-name="Group 23201">
                  <g id="Group_23200" data-name="Group 23200">
                    <path id="Path_13484" data-name="Path 13484" d="M515.434,258.348l1.535,11.822.813.035-.254-8.5Z" transform="translate(-515.434 -258.348)" fill="#6069a8" />
                  </g>
                </g>
              </g>
              <g id="Group_23205" data-name="Group 23205" transform="translate(542.708 278.141)">
                <g id="Group_23204" data-name="Group 23204">
                  <g id="Group_23203" data-name="Group 23203">
                    <path id="Path_13485" data-name="Path 13485" d="M508,221.664l52.556,23.948a10.1,10.1,0,0,1,6.524,8.421c2.05,20.331,17.26,52.585,17.26,52.585,4.879,2.387,4.5.315,4.5.315-3.073-9.871-6.818-50.555-8.155-66.911-.282-3.464-.457-5.838-.493-6.6-.027-.515-.593-1.193-1.566-1.981-7.211-5.847-36.887-17.955-36.887-17.955C519.46,212.927,508,221.664,508,221.664Z" transform="translate(-507.997 -213.465)" fill="#de9674" />
                  </g>
                </g>
              </g>
              <g id="Group_23208" data-name="Group 23208" transform="translate(532.59 274.529)">
                <g id="Group_23207" data-name="Group 23207">
                  <g id="Group_23206" data-name="Group 23206">
                    <path id="Path_13486" data-name="Path 13486" d="M503.652,233.933c1.626,14.206,21.905,12.578,21.905,12.578h24.577s-6.456,14.366-8.95,26.482c-2.643,12.858-8.881,35.61-8.881,35.61s.411.773,4.857.34c0,0,19.292-38.45,26.678-48.117,1.586-2.081,6.14-13.371,6.924-20.107.264-2.267.1-4.017-.744-4.726a61.2,61.2,0,0,0-5.428-3.709c-7.7-4.855-21.066-12.327-28.514-14-9.978-2.243-25.756-6.445-25.756-6.445S502.026,219.727,503.652,233.933Z" transform="translate(-503.441 -211.839)" fill="#eea886" />
                  </g>
                </g>
              </g>
              <g id="Group_23209" data-name="Group 23209" transform="translate(547.149 160.485)">
                <path id="Path_13487" data-name="Path 13487" d="M512.375,187.2s-3.68-6.984-1.885-14.422,8.694-14.848,18.8-11.446,18.272,11.253,12.214,20.329,5.481,13.176,2.543,17.495-1.825,8.827-.582,9.833,5.259,4.586,4.819,8.825-19.6-12.356-19.6-12.356S518.243,184.491,512.375,187.2Z" transform="translate(-509.997 -160.485)" fill="#464240" />
              </g>
              <g id="Group_23210" data-name="Group 23210" transform="translate(539.55 273.049)">
                <path id="Path_13488" data-name="Path 13488" d="M547.35,214.587s-1.521,7.393-40.775-1.941C506.575,212.646,519.735,208.276,547.35,214.587Z" transform="translate(-506.575 -211.172)" fill="#eb4747" />
              </g>
              <g id="Group_23212" data-name="Group 23212" transform="translate(531.127 205.871)">
                <g id="Group_23211" data-name="Group 23211">
                  <path id="Path_13489" data-name="Path 13489" d="M510.761,251.648l-1.825,3.484q21.526.626,43.045,1.255c-2.267-7.6-1.532-17.893-1.135-25.705.484-9.54,5.756-16.176,5.194-25.825,2.838.458,3.329.546,6.172.982-4.346-12.39-5.836-18.525-19.343-23.293a25.222,25.222,0,0,0-12.045-1.468c-15.643,1.29-20.36,1.475-28.041,15.647,12.025,1.914,10.047,6.234,13.693,18.2,1.779,5.834,3.635,12.345,2.68,18.5C518.218,239.478,510.761,246.123,510.761,251.648Z" transform="translate(-502.782 -180.922)" fill="#4b9aff" />
                </g>
              </g>
              <g id="Group_23214" data-name="Group 23214" transform="translate(558.907 193.504)">
                <g id="Group_23213" data-name="Group 23213">
                  <path id="Path_13490" data-name="Path 13490" d="M520.452,192.345c-6.4-1.21-5.039-4.77-5.039-4.77s.3-2.01.411-4.548c.031-.651.049-1.337.047-2.032a27.728,27.728,0,0,0-.178-3.149c-.524-4.3,11.621-1.734,11.621-1.734s-.7,8.7.973,11.3S526.854,193.554,520.452,192.345Z" transform="translate(-515.291 -175.354)" fill="#eea886" />
                </g>
              </g>
              <g id="Group_23216" data-name="Group 23216" transform="translate(551.855 204.538)">
                <g id="Group_23215" data-name="Group 23215">
                  <path id="Path_13491" data-name="Path 13491" d="M512.116,181.488S525.03,196.4,529.8,199.854c0,0,5.485-4.957,2.1-18.044C531.9,181.81,525.674,178.688,512.116,181.488Z" transform="translate(-512.116 -180.322)" fill="#eea886" />
                </g>
              </g>
              <g id="Group_23217" data-name="Group 23217" transform="translate(549.211 167.415)">
                <path id="Path_13492" data-name="Path 13492" d="M513.921,166.009s-6.7,12.92-.053,25.159,19.718-5.33,20.564-7.391,5.325-13.72-3.589-17.264S515.819,162.5,513.921,166.009Z" transform="translate(-510.925 -163.606)" fill="#eea886" />
              </g>
              <g id="Group_23218" data-name="Group 23218" transform="translate(548.959 164.738)">
                <path id="Path_13493" data-name="Path 13493" d="M516.289,172.065s-4.615,6.063-5.161,10.542A24.012,24.012,0,0,1,514.485,166c5.2-8.139,18.408-.386,20.178,2.865s6.955,6.116-1.757,20.089C532.906,188.954,517.237,178.067,516.289,172.065Z" transform="translate(-510.812 -162.4)" fill="#464240" />
              </g>
              <g id="Group_23219" data-name="Group 23219" transform="translate(559.439 196.268)">
                <path id="Path_13494" data-name="Path 13494" d="M521.245,176.6s-1.164,2.543-5.714,4.91c.031-.651.049-1.337.047-2.032A10.874,10.874,0,0,0,521.245,176.6Z" transform="translate(-515.531 -176.598)" fill="#a77860" />
              </g>
              <g id="Group_23220" data-name="Group 23220" transform="translate(484.075 264.514)">
                <path id="Path_13495" data-name="Path 13495" d="M494.9,207.787s-3.189,1.417-4.7.775a14.115,14.115,0,0,0-4.588-.771c-.986,0-4.073-1.1-4.017.1s5.01,2.048,6.025,2.425-3.036,2.6-3.413,3.6,4.2,7.488,6.773,5.636,3.975-5.547,4.5-5.783a18.534,18.534,0,0,0,1.934-1.272Z" transform="translate(-481.595 -207.329)" fill="#eea886" />
              </g>
              <g id="Group_23221" data-name="Group 23221" transform="translate(479.563 270.523)">
                <path id="Path_13496" data-name="Path 13496" d="M487.176,210.035s-7.475,6.2-7.593,7.035.275,1.228,1,.677S488,211.971,488,211.971s-7.49,8.847-7.741,9.5c-.147.384.571,1.184.988.926s8.385-8.588,8.385-8.588-6.978,8.275-7.018,8.894a.813.813,0,0,0,1.372.549c.564-.577,7.506-7.877,7.506-7.877s-4.668,6.351-4.379,6.911c.175.342.766,1.059,1.914-.62s4.31-5.339,4.31-5.339Z" transform="translate(-479.563 -210.035)" fill="#eea886" />
              </g>
              <g id="Group_23223" data-name="Group 23223" transform="translate(532.592 274.529)">
                <g id="Group_23222" data-name="Group 23222">
                  <path id="Path_13497" data-name="Path 13497" d="M547.569,246.514H525.557s-20.28,1.626-21.9-12.581,6.667-22.094,6.667-22.094,10.713,2.851,20.064,5.112a72.479,72.479,0,0,1,16.918-1.477s39.647,13.1,38.454,20C585.757,235.474,569.172,226.607,547.569,246.514Z" transform="translate(-503.442 -211.839)" fill="#ffc955" />
                </g>
              </g>
              <g id="Group_23224" data-name="Group 23224" transform="translate(562.391 283.987)">
                <path id="Path_13498" data-name="Path 13498" d="M516.86,216.715l41.969-.617-21.19,10.759Z" transform="translate(-516.86 -216.098)" fill="#e94d4d" />
              </g>
              <g id="Group_23225" data-name="Group 23225" transform="translate(553.872 275.271)">
                <path id="Path_13499" data-name="Path 13499" d="M534.581,216.144a35.909,35.909,0,0,1-21.015.351l-.542-4.322,23.829,1.326Z" transform="translate(-513.024 -212.173)" fill="#4b9aff" />
              </g>
              <g id="Group_23226" data-name="Group 23226" transform="translate(562.391 284.604)">
                <path id="Path_13500" data-name="Path 13500" d="M537.639,226.517l-20.778-8.049v-2.093l22.422,8.088Z" transform="translate(-516.86 -216.375)" fill="#efadad" />
              </g>
              <g id="Group_23229" data-name="Group 23229" transform="translate(493.714 204.773)">
                <g id="Group_23227" data-name="Group 23227" transform="translate(77.931 1.253)">
                  <path id="Path_13501" data-name="Path 13501" d="M524.667,256.206a2.074,2.074,0,0,0,2.885,2.059l2.436-1.039a1.734,1.734,0,0,0,1.033-1.872c-1.015-6.347-3.191-31.057-.011-35.3a15.352,15.352,0,0,0,2.325-4.142c2.1,5.416,4.713,12.392,5.752,16.145l19.829,26.378,6.647,1.685c-8.294-19.989-14.959-30.58-14.959-30.58-1.3-3.484-5.019-14.033-8.314-23.427-2.081-5.932-3.991-11.4-5.019-14.342a6.731,6.731,0,0,0-2.9-3.555c-3.071-1.837-8.188-4.728-12.829-6.982l-.058-.027c-.146-.071-.293-.144-.444-.213l-.007,0,0,0c8.825,13.351,5.383,51.053,3.642,75.161Z" transform="translate(-521.027 -180.992)" fill="#f1eeee" />
                </g>
                <g id="Group_23228" data-name="Group 23228">
                  <path id="Path_13502" data-name="Path 13502" d="M534.749,182.82c-2.827.437-9.836,7.24-13.558,14.108-4.248,7.837-8.57,16.762-11.812,19.7-5.958,5.4-22.636,24.479-23.431,24.017a52.121,52.121,0,0,0,5.194,5.974c.311.135,27.415-23.351,30.02-26.929,2.467-3.393,9.069-17.63,11.044-17.317l0,.009,0,0a105.652,105.652,0,0,1,4.828,13.14l.113.38c1.568,5.308,1.424,11.07.575,17.544-.642,4.89-4.29,12.878-6.491,16.767a2.638,2.638,0,0,0,.089,2.034v0a1.448,1.448,0,0,0,.722.842l7.959,2.949,1.87.691a4.076,4.076,0,0,0,5.456-3.291c1.77-13.611,4.008-27.517,5.792-32.756,7.83-22.987-3.06-40.258-3.06-40.258S537.23,181.894,534.749,182.82Z" transform="translate(-485.935 -180.428)" fill="#f1eeee" />
                </g>
              </g>
              <g id="Group_23230" data-name="Group 23230" transform="translate(583.17 260.662)">
                <path id="Path_13503" data-name="Path 13503" d="M526.217,239.678,538.7,205.595l40.244.657-11.9,31.408Z" transform="translate(-526.217 -205.595)" fill="#ff6464" />
              </g>
              <g id="Group_23231" data-name="Group 23231" transform="translate(609.533 274.716)">
                <path id="Path_13504" data-name="Path 13504" d="M537.7,212.677c-.666,1.333-.466,2.783.449,3.24s2.2-.251,2.861-1.583.465-2.782-.449-3.24S538.369,211.345,537.7,212.677Z" transform="translate(-537.31 -210.955)" fill="#f8f8f8" />
              </g>
              <g id="Group_23232" data-name="Group 23232" transform="translate(563.595 182.41)">
                <path id="Path_13505" data-name="Path 13505" d="M519.915,170.632s2.578,4.011.2,7.473.6,6.862,1.117,10.522-9.94,13.4,1.69,18.295,15.332-3.2,12.365-7.655-5.072-8.07-5.323-14.426S527.617,168.584,519.915,170.632Z" transform="translate(-517.402 -170.358)" fill="#464240" />
              </g>
            </g>
            <g id="Group_23270" data-name="Group 23270" transform="translate(0 -88)">
              <line id="Line_134" data-name="Line 134" y2={191} transform="translate(1291.475 5168.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_135" data-name="Line 135" y2="384.918" transform="translate(1401.475 5359.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_138" data-name="Line 138" x1="109.975" transform="translate(1291.5 5354.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            </g>
            <g id="General_Settings" data-name="General Settings" transform="translate(595 4557)">
              <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(689 702)" fill="#ff6464" />
              <circle id="Ellipse_1377" data-name="Ellipse 1377" cx="7.5" cy="7.5" r="7.5" transform="translate(117 444)" fill="#6492ff" />
              <circle id="Ellipse_1378" data-name="Ellipse 1378" cx="7.5" cy="7.5" r="7.5" transform="translate(337 851)" fill="#6492ff" />
              <circle id="Ellipse_1380" data-name="Ellipse 1380" cx="7.5" cy="7.5" r="7.5" transform="translate(210 1271)" fill="#6492ff" />
              <circle id="Ellipse_1381" data-name="Ellipse 1381" cx="7.5" cy="7.5" r="7.5" transform="translate(560 1241)" fill="#6492ff" />
              <circle id="Ellipse_1382" data-name="Ellipse 1382" cx="7.5" cy="7.5" r="7.5" transform="translate(650 1084)" fill="#ff6464" />
              <circle id="Ellipse_1379" data-name="Ellipse 1379" cx="7.5" cy="7.5" r="7.5" transform="translate(602 948)" fill="#ff6464" />
              <text id="Manage_User_Wallet_Address" data-name="Manage User 
Wallet Address" transform="translate(654 704.5)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x="-107.12" y={0}>Manage User </tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-123.264" y={20}>Wallet Address</tspan></tspan></text>
              <g id="Group_23267" data-name="Group 23267" transform="translate(-437 -4652)">
                <g id="Group_23276" data-name="Group 23276" transform="translate(0 186)">
                  <g id="Rectangle_5489" data-name="Rectangle 5489" transform="translate(1125.75 5533)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                    <rect width={238} height={50} rx={15} stroke="none" />
                    <rect x={1} y={1} width={236} height={48} rx={14} fill="none" />
                  </g>
                  <text id="Admin_Profit_Management" data-name="Admin Profit Management" transform="translate(1154.75 5563)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Admin Profit Management</tspan></text>
                </g>
                <g id="Group_23274" data-name="Group 23274" transform="translate(-51 96)">
                  <g id="Rectangle_5488" data-name="Rectangle 5488" transform="translate(1126 5483)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                    <rect width={340} height={50} rx={15} stroke="none" />
                    <rect x={1} y={1} width={338} height={48} rx={14} fill="none" />
                  </g>
                  <text id="Receives_Commission_for_the_Minting" data-name="Receives Commission for the Minting" transform="translate(1174 5513)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Receives Commission</tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}> for the Minting</tspan></text>
                </g>
                <g id="Group_23275" data-name="Group 23275" transform="translate(-55.75)">
                  <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(1125.75 5439)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                    <rect width={350} height={50} rx={15} stroke="none" />
                    <rect x={1} y={1} width={348} height={48} rx={14} fill="none" />
                  </g>
                  <text id="View_the_users_Bidding_Collectibles" data-name="View the users Bidding Collectibles" transform="translate(1184.75 5469)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>View the users </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Bidding Collectibles</tspan></text>
                </g>
              </g>
            </g>
            <g id="Rectangle_8831" data-name="Rectangle 8831" transform="translate(648.75 5893.918)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <rect width={345} height={82} rx={25} stroke="none" />
              <rect x={1} y={1} width={343} height={80} rx={24} fill="none" />
            </g>
            <g id="Rectangle_8832" data-name="Rectangle 8832" transform="translate(1193.75 5761.918)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <rect width={345} height={82} rx={25} stroke="none" />
              <rect x={1} y={1} width={343} height={80} rx={24} fill="none" />
            </g>
            <g id="Rectangle_8833" data-name="Rectangle 8833" transform="translate(1193.75 5893.918)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <rect width={345} height={82} rx={25} stroke="none" />
              <rect x={1} y={1} width={343} height={80} rx={24} fill="none" />
            </g>
            <text id="Receives_Ownership_Rewards" data-name="Receives Ownership 
Rewards" transform="translate(1084.75 5459.918)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-85.512" y={0}>Receives Ownership </tspan><tspan x="-34.976" y={20}>Rewards</tspan></text>
            <text id="Buyers_Bid_the_NFTs_Listed_by_Creators" data-name="Buyers Bid the NFTs 
Listed by Creators" transform="translate(820.75 5929.918)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-83.256" y={0}>Buyers Bid the NFTs </tspan><tspan x="-73.984" y={20}>Listed by Creators</tspan></text>
            <text id="NFT_Sold_to_the_highest_bid_price_after_bidding_period_is_over" data-name="NFT Sold to the highest bid price 
after bidding period is over" transform="translate(1366.75 5797.918)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-136.96" y={0}>NFT Sold to the highest bid price </tspan><tspan x="-112.92" y={20}>after bidding period is over</tspan></text>
            <text id="NFTs_arc_transferred_safely_to_the_Digital_Wallet" data-name="NFTs arc transferred safely to 
the Digital Wallet" transform="translate(1366.75 5929.918)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-121.448" y={0}>NFTs arc transferred safely to </tspan><tspan x="-71.696" y={20}>the Digital Wallet</tspan></text>
          </g>
        </svg>
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/superrare-mobile.webp" 
              alt="superrare-clone-script-workflow"
              width={392} />
              )}
        
      </div>
    </section>
    </div>
  )
}

export default HowOur