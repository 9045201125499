import React from 'react'
import Slider from "react-slick";
import ButtonComponent from '../ButtonComponent/ButtonComponent'



class Banner extends React.Component {

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">SuperRare</span> Clone Script</h1>
                <p className="sub-heading">Buy the best SuperRare clone script to launch your own NFT Marketplace with prominent features</p>
                <ul>
                  <li className="pharagraph">Launch your marketplace at the fastest possible speed.
                  </li>
                  <li className="pharagraph">Based on the trusted Ethereum blockchain.
                  </li>
                  <li className="pharagraph">Real-time updates on the highest performing pairs.
                  </li>
                </ul>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
            <div className="screen">
                <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png" alt="superrare clone laptop front view" />
                <div className="outder">
                  <Slider {...settings}>
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/white-label-superrare-clone-script-software.png" alt="white label superrare clone script software" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/superrare-clone-script.png" alt="superrare clone script" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/superrare-new/superrare-clone.png" alt="superrare-clone" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner